import { Navigate, useLocation } from "@solidjs/router";
import { Match, type ParentComponent, Switch, lazy, Show } from "solid-js";
import {
  type CampaignPageData,
  type CampaingPageDataLevel,
  type CampaingPageDataPrompt,
  useUseCasesData,
} from "~/domains/marketing/useUseCasesData";
import { useWire } from "~/wire";

const HomeScreen = lazy(() =>
  import("~/domains/marketing/screens/HomeScreen").then((r) => ({
    default: r.HomeScreen,
  })),
);
const MarketingLayout = lazy(() =>
  import("~/components/layouts/MarketingLayout/MarketingLayout").then((r) => ({
    default: r.MarketingLayout,
  })),
);
const ThreadScreen = lazy(() =>
  import("~/domains/threads/screens/ThreadScreen").then((r) => ({
    default: r.ThreadScreen,
  })),
);

export const Layout: ParentComponent = (props) => {
  const location = useLocation();
  const { data } = useUseCasesData(() => location.pathname);
  const wire = useWire();

  return (
    <>
      <Switch>
        <Match
          when={
            data() &&
            // biome-ignore lint/style/noNonNullAssertion: <explanation>
            "redirect" in data()!
          }
        >
          <Navigate href={(data() as { redirect: string }).redirect} />
        </Match>
        <Match when={(data() as CampaignPageData)?.type === "level"}>
          <MarketingLayout>
            <HomeScreen data={data() as CampaingPageDataLevel} />
          </MarketingLayout>
        </Match>
        <Match when={(data() as CampaignPageData)?.type === "prompt"}>
          <Show when={!wire.metadata.isDefault}>
            <ThreadScreen
              location={location}
              params={{}}
              prompt={data() as CampaingPageDataPrompt}
            />
          </Show>
        </Match>
        <Match when={location.pathname.startsWith("/project")}>
          <Show when={!wire.metadata.isDefault}>{props.children}</Show>
        </Match>
        <Match when={true}>
          <MarketingLayout>{props.children}</MarketingLayout>
        </Match>
      </Switch>
    </>
  );
};
