import { type Accessor, createMemo, children } from "solid-js";
import campaigns from "./data/campaigns.gen.json";

export type CampaignLevel = {
  id: string;
  name: string;
  pre?: string;
  children: string[];
};
export type CampaignPrompt = (typeof campaigns.prompts)[number];

export type NormalizedCampaignLevel = Omit<CampaignLevel, "children"> & {
  type: "l0" | "l1" | "l2" | "l3";
  children: NormalizedCampaignLevel[] | CampaignLevel[] | CampaignPrompt[];
  prompts?: CampaignPrompt[];
};

export type CampaingPageDataLevel = {
  type: "level";
  breadcrumbs: string[];
  level: NormalizedCampaignLevel;
};
export type CampaingPageDataPrompt = {
  type: "prompt";
  breadcrumbs: string[];
  prompt: CampaignPrompt;
};
export type CampaignPageData = CampaingPageDataLevel | CampaingPageDataPrompt;

const normalizeLevel = (
  type: NormalizedCampaignLevel["type"],
  l: CampaignLevel,
  next: CampaignLevel[] | CampaignPrompt[],
): NormalizedCampaignLevel => {
  const children = !l.children
    ? []
    : (l.children
        .map((l) => next.find((n) => n.id === l))
        .filter((l) => l) as CampaignLevel[]);

  return {
    type,
    ...l,
    children,
    pre: l.pre?.startsWith("Effective") ? l.pre : `Effective ${l.pre ?? "at"}`,
    prompts: getPromptsForLevel(type, l.id),
  };
};
const getRedirect = (b: string[]) => `/${b.join("/")}`;

const buildL1L2Prompts = () => {
  const l1: Record<string, string[]> = {};
  const l2: Record<string, string[]> = {};

  campaigns.l2.forEach((l) => {
    const p = campaigns.prompts
      .filter((p) => p.parents.some((parent) => l.children.includes(parent)))
      .map((p) => p.id || "");

    l2[l.id] = Array.from(new Set(p));
  });

  campaigns.l1.forEach((l) => {
    l1[l.id] = [];
    l.children.forEach((child) => {
      const p = l2[child];
      l1[l.id]?.push(...(p || []));
      // Make prompts unique
      l1[l.id] = Array.from(new Set(l1[l.id]));
    });
  });

  return {
    l1,
    l2,
  };
};
const L1_L2_PROMPTS = buildL1L2Prompts();
const getPromptsForLevel = (level: string, id: string) => {
  if (level === "l1")
    return campaigns.prompts.filter((p) =>
      L1_L2_PROMPTS.l1[id]?.includes(p.id || ""),
    );
  if (level === "l2")
    return campaigns.prompts.filter((p) =>
      L1_L2_PROMPTS.l2[id]?.includes(p.id || ""),
    );
  return undefined;
};

export const useUseCasesData = (pathname: Accessor<string>) => {
  const data = createMemo(
    (): CampaignPageData | { redirect: string } | undefined => {
      // Special handler for homepage
      if (pathname() === "/")
        return {
          type: "level",
          breadcrumbs: [],
          level: {
            type: "l0",
            id: "",
            name: "",
            pre: "Effective",
            children: campaigns.l1.map((l) => ({
              ...normalizeLevel("l1", l, campaigns.l2),
              pre: l.pre.split(" ")[1],
            })),
          },
        };

      // Special handler for /prompts where we show all prompts
      if (pathname() === "/prompts") {
        return {
          type: "level",
          breadcrumbs: ["prompts"],
          level: {
            type: "l3",
            id: "",
            name: "",
            pre: "Effective",
            children: campaigns.prompts,
          },
        };
      }

      const filters = pathname()
        .split("/")
        .filter((i) => i);
      if (filters.length === 0) return;

      if (filters[0] === "prompts" && filters[1]) {
        const prompt = campaigns.prompts.find((l) => l.id === filters[1]);
        if (!prompt) return { redirect: getRedirect(["prompts"]) };
        return { type: "prompt", breadcrumbs: ["prompts"], prompt };
      }

      const breadcrumbs: string[] = [];

      const l1 = campaigns.l1.find((l) => l.id === filters[0]);
      if (!l1) return;
      breadcrumbs.push(l1.id);
      if (filters.length === 1)
        return {
          type: "level",
          breadcrumbs,
          level: normalizeLevel("l1", l1, campaigns.l2),
        };

      const l2 = campaigns.l2.find((l) => l.id === filters[1]);
      if (!l2) return { redirect: getRedirect(breadcrumbs) };
      breadcrumbs.push(l2.id);
      if (filters.length === 2)
        return {
          type: "level",
          breadcrumbs,
          level: normalizeLevel("l2", l2, campaigns.l3),
        };

      const l3 = campaigns.l3.find((l) => l.id === filters[2]);
      if (!l3) return { redirect: getRedirect(breadcrumbs) };
      breadcrumbs.push(l3.id);
      if (filters.length === 3)
        return {
          type: "level",
          breadcrumbs,
          level: normalizeLevel("l3", l3, campaigns.prompts),
        };

      const prompt = campaigns.prompts.find((l) => l.id === filters[3]);
      if (!prompt) return { redirect: getRedirect(breadcrumbs) };
      breadcrumbs.push(prompt.id || "");
      if (filters.length === 4) return { type: "prompt", breadcrumbs, prompt };

      return { redirect: getRedirect(breadcrumbs) };
    },
  );

  return { data };
};
